/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import { graphql, navigate } from 'gatsby'
import { Layout } from '../components'
import { Seo } from '../components/base'
import { Lunch } from '../components/lunch/Lunch'
import { ClientRender } from '../components/shared'
import { getLocaleMarket } from '../utils/getLocaleMarket'
import {
  getLocale,
  getLocaleMarketUrl,
  getLocaleUrl
} from '../utils/localeUtils'
import { lunchPath } from '../utils'
const LunchPage = ({ data, pageContext, location }) => {
  const { seo } = data.sanityLunchPage.nodes[0]
  let myMarket = getLocaleMarket()
  const market = data.sanityMarket
  const languages = { pageContext }
  const locale = getLocale()

  useEffect(() => {
    const isBrowser = () => typeof window !== 'undefined'
    if (isBrowser) {
      if (myMarket && !location.pathname.includes(myMarket.slug)) {
        navigate(
          getLocaleMarketUrl(locale, myMarket?.countryCode, lunchPath(myMarket))
        )
      } else if (!myMarket) navigate(getLocaleUrl(locale, '/mittagsgerichte'))
    }
  }, [locale])

  return (
    <ClientRender>
      <Layout standardPaddingTopFromNavbarToMain translations={languages}>
        <Seo
          title={seo?.metaTitle}
          description={seo?.metaDescription}
          image={seo?.ogImage?.asset?.url}
        />
        <Lunch data={{ ...data }} market={market || myMarket} />
      </Layout>
    </ClientRender>
  )
}

export default LunchPage

export const lunchQuery = graphql`
  query (
    $marketId: String!
    $calendarWeek: Float!
    $nextWeek: Float!
    $language: String!
  ) {
    allSanityLunchOffer(
      filter: { week: { eq: $calendarWeek }, marketId: { eq: $marketId } }
    ) {
      nodes {
        ...LunchOfferQuery
      }
    }
    nextLunchOffer: allSanityLunchOffer(
      filter: { week: { eq: $nextWeek }, marketId: { eq: $marketId } }
    ) {
      nodes {
        ...LunchOfferQuery
      }
    }
    sanityLunchPage: allSanityLunchPage(
      filter: {
        i18n_lang: { eq: $language }
        isNotAvailableInCountry: { ne: true }
      }
    ) {
      nodes {
        ...LunchPageQuery
      }
    }
    sanityMarket(marketId: { eq: $marketId }) {
      ...MarketQuery
    }
  }
`
